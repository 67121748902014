<div
  class="card"
  fxFlex="100"
  fxLayout="column"
  fxLayoutAlign="center center"
  *transloco="let translate"
  [ngClass]="{ 'card--update': source === 'versionUpdate' }"
>
  <svg-icon class="card__close" src="assets/icons/close.svg" (click)="onCloseClick()"></svg-icon>

  @if(source === 'accessDenied') {
  <svg-icon class="card__image" [ngClass]="'card__image__denied'" src="/assets/icons/key.svg"></svg-icon>
  } @else {
  <svg-icon
    class="card__image"
    [ngClass]="{
      'svg-to-primary card__image--update': source === 'versionUpdate',
      'card__image__train-visits': source === 'train-visits'
    }"
    [src]="
      source === 'versionUpdate'
        ? 'assets/icons/check.svg'
        : source === 'train-visits'
        ? 'assets/icons/summary/service_dangerous_goods.svg'
        : 'assets/icons/delete_confirm.svg'
    "
  ></svg-icon>
  }

  <div class="card__headline">
    {{ translate(TRANSLOCO_PREFIX + '.' + source + '.title') }}
  </div>

  @if (source === 'booking' && status === 'IN_CAPTURE') {
  <div class="card__text">
    {{ translate(TRANSLOCO_PREFIX + '.' + source + '.text-draft', { value: data }) }}
  </div>
  } @else {
  <div class="card__text">
    {{ translate(TRANSLOCO_PREFIX + '.' + source + '.text', { value: data }) }}
  </div>
  } @if(source === 'train-visits'){
  <div fxFlex="100" fxLayout="row" fxLayoutGap="20px">
    <button egButton class="card__button" [ngClass]="'card__button_red'" (click)="onResponseClick('yes')">
      {{ translate('confirmation.action.yes') }}
    </button>
    <button egButton class="card__button" [ngClass]="'card__button_secondary--cancellation'" (click)="onResponseClick('no')">
      {{ translate('confirmation.action.no') }}
    </button>
  </div>
  } @else {
  <button egButton class="card__button" (click)="onResponseClick()" [ngClass]="{ 'card__button--update': source === 'versionUpdate' }">
    {{ translate(TRANSLOCO_PREFIX + '.action.' + type) }}
  </button>
  }
</div>
