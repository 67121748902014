<div [style.width.px]="prefixContainerWidth" class="inline absolute eg-input__prefix">
  <ng-content class="inline-content" select="[egFieldPrefixDirective]"></ng-content>
</div>

<textarea
  class="eg-input__textarea"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [disabled]="disabled"
  (change)="textareaChanged()"
  (keyup)="onKeyup($event)"
  (blur)="markInputAsTouched()"
  [rows]="rows"
></textarea>

<label [for]="id" [style.left.px]="prefixContainerWidth + prefixInputPadding" class="absolute eg-input__label">
  {{ labelWithSuffix }}
</label>

<div class="inline absolute eg-input__suffix">
  <ng-content select="[egFieldSuffixDirective]"></ng-content>
</div>
@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="eg-input__error"></eg-form-error>
}
