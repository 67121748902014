<div class="mt-5">
  @if (isDocumentScaning) {
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" *transloco="let translate">
    <eg-spinner size="30" fxLayout="column" fxLayoutAlign="center center"></eg-spinner>
    <p class="ml-15 scan-message">{{ translate(TRANSLOCO_PREFIX + '.scaningMessage') }}</p>
  </div>
  } @else {
  <input
    type="file"
    class="upload__input"
    [name]="formControlName"
    accept=".pdf"
    [readonly]="readonly"
    [disabled]="disabled"
    (change)="onChanged($event)"
    #upload
  />

  @if (!file) {
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="upload__button" (click)="upload.click()">
    <eg-tile-add class="upload__icon"></eg-tile-add>
    <span class="upload__label"> {{ label || labelWithSuffix }} </span>
  </div>
  } @else {
  <div (click)="download(file.documentKey)" fxFlex="100" fxLayout="row" class="upload__file" fxLayoutAlign="start center">
    <span class="label"> {{ documentType }} </span>
    <div class="file">
      <span class="file-icon"></span>
      <span class="file-name"> {{ file.documentKey }} </span>
    </div>
    @if (deletable) {
    <span class="delete" (click)="removeSelection(file.documentKey)"></span>
    }
  </div>
  } @if (control?.touched && control?.errors && errorMessage) {
  <eg-form-error [errorMessage]="errorMessage | async" class="input-error"></eg-form-error>
  } }
</div>
