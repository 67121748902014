import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer, FormControl } from '@angular/forms';
import { TrainStatusTypeModel, TrainVisitModel, TrainVisitService } from '@railmybox/api-dispo';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'rmb-trainvisit-select',
  templateUrl: './trainvisit-select.component.html',
  styleUrls: ['./trainvisit-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TrainvisitSelectComponent)],
})
export class TrainvisitSelectComponent extends AbstractValueAccessorComponent<TrainVisitModel | string> implements OnInit {
  trainVisits$: Observable<TrainVisitModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedTrain: string;
  @Input() filterTrainVisits: string;
  @Input() displayValue = 'trainIdOperator';
  @Input() clearable = true;

  constructor(
    private trainVisitService: TrainVisitService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    const trainStatuses = [TrainStatusTypeModel.Active, TrainStatusTypeModel.Delayed];
    this.trainVisits$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) =>
        query
          ? this.trainVisitService.searchTrainVisits({
              trainStatus: trainStatuses,
              trainIdOperator: query,
            })
          : of([])
      ),
      map((trainVisits: TrainVisitModel[]) =>
        this.filterTrainVisits ? trainVisits.filter((visit) => visit.trainIdOperator.includes(this.filterTrainVisits)) : trainVisits
      ),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(trainVisit: TrainVisitModel): void {
    this.selectedTrain = trainVisit ? trainVisit[this.displayValue] : null;
    this.value = trainVisit;
    this.onChange(trainVisit);
    this.changed.emit(trainVisit);
  }

  async writeValue(value: TrainVisitModel | string): Promise<void> {
    if (!value) {
      return;
    }

    super.writeValue(value);

    const trainId = typeof value === 'string' ? value : value?.id;
    const train = await this.trainVisitService.getTrainVisit(trainId).subscribe((train) => {
      // In case no entry found with that id
      if (!train) {
        return;
      }

      this.value = train;
      this.selectedTrain = train[this.displayValue];
    });
  }
}
