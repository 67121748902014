<div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" *transloco="let translate">
  <div
    [ngClass]="{
      chain__active: dispoOrder?.reference === reference,
      'chain__missing-data': dispoOrder?.missingData && dispoOrder?.transportSection === 'MAINRUN'
    }"
    class="chain"
    fxLayout="column"
    fxLayoutGap="8px"
    (click)="goToOrder(dispoOrder)"
  >
    <div fxFlex="100" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <div fxFlex="60" class="chain__key">{{ dispoOrder?.reference }}</div>
      <div fxFlex fxLayoutAlign="end center">
        <eg-pill [value]="dispoOrder?.status" translationPrefix="dashboard.booking.state." [colors]="dispoStatusColors"></eg-pill>
      </div>
    </div>
    <div fxFlex="100">
      {{ translate('dispo.dispo.section.' + dispoOrder?.transportSection) }}
    </div>
    <div fxFlex="100">
      {{ dispoOrder?.date | egDate: 'date-time' }}
    </div>
  </div>
  <img src="/assets/icons/arrow_right.svg" />
</div>
