import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from 'primeng/editor';
import { TextEditorComponent } from './text-editor.component';
import { FormsModule } from '@angular/forms';
import { InputModule } from '../input/input.module';
import { FormErrorModule } from '../form-error/form-error.module';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [TextEditorComponent],
  imports: [CommonModule, FormErrorModule, FormsModule, SharedDirectiveModule, InputModule, EditorModule],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
