import { PillColorType } from '@eg/ui';

export const dispoStatusColors: PillColorType = {
  CANCELLED: 'grey',
  REBOOKING_CANCELLED: 'grey',
  ACCEPTED: 'lightGreen',
  VERIFICATION: 'orange',
  REBOOKING: 'orange',
  REQUEST_CANCELLATION: 'orange',
  DISPATCHED: 'green',
  BOOKING_CLOSED: 'green',
  IN_PROGRESS: 'green',
  ARRIVED: 'green',
  DONE: 'blue',
  TRANSPORT_PROBLEM: 'red',
  DENIED: 'red',
  MISSING_DATA: 'orange',
  MISSING_DATA_URGENT: 'red',
};

export const registrationStepColors: PillColorType = {
  COMPANY: 'lightRed',
  PAYMENT: 'lightOrange',
  FINISHED: 'lightGreen',
};

export const contactTypeColors: PillColorType = {
  ADMIN: 'lightGreen',
  USER: 'grey',
};

export const invoiceStatusColors: PillColorType = {
  CREATED: 'orange',
  DONE: 'lightGreen',
  CANCELLED: 'grey',
};
