import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type FieldType = 'M' | 'O' | '-';

@UntilDestroy()
@Component({ template: '' })
export abstract class AbstractValueAccessorComponent<T = unknown> implements ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  private _status: string;
  _fieldType: string;

  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() disabled = false;
  @Input() value?: T;

  @Input() set fieldType(fieldType: string) {
    this._fieldType = fieldType;
  }

  @Input() name: string;
  @Input() placeholder = '';
  @Input() readonly = false;
  @Input() translationPrefix: string;
  @Input() label: string;
  @Output() changed: EventEmitter<T> = new EventEmitter<T>();
  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  idSuffix = '.' + Math.floor(Math.random() * 1000);
  labelWithSuffix: string;
  errorMessage: string;
  showErrorIcon = false;
  /* eslint-disable */
  onChange: (value: T) => void = (_: T) => {};
  onTouched: () => void = () => {};

  /* eslint-enable */

  @Input() set status(status: string) {
    if (status === 'PROBLEM') {
      this._fieldType = 'M';
    }
    this._status = status;
  }

  get status(): string {
    return this._status;
  }

  @HostBinding('class') get statusClasses(): string {
    if (this.status === 'WARNING') {
      return 'ng-warning';
    }

    if (this.status === 'PROBLEM') {
      return 'ng-problem';
    }

    /*if (this.status === 'PROBLEM' || (this.control?.invalid && this.control?.touched)) {

      return 'ng-problem';
    }
    */
    return null;
  }

  get control() {
    return this.formControl || this.controlContainer?.control.get(this.formControlName);
  }

  get controlContainer() {
    return this._controlContainer;
  }

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() private _controlContainer: ControlContainer) {}

  ngAfterViewInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe({
        next: () => {
          this.getErrors();
          this.changeDetectorRef.markForCheck();
        },
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.label) {
      this.labelWithSuffix = '';
      return;
    }

    switch (this._fieldType) {
      case 'M':
        this.labelWithSuffix = `${this.label}*`;
        break;
      case 'O':
        this.labelWithSuffix = `${this.label} (${translate('forms.optional')})`;
        break;
      default:
        this.labelWithSuffix = this.label;
    }
  }

  /**
   * Registers change listener for custom form control
   */
  registerOnChange(onChange: (_: T) => void): void {
    this.onChange = onChange;
  }

  /**
   * Registers change listener for custom form control
   */
  registerOnTouched(onTouch: () => void): void {
    this.onTouched = onTouch;
  }

  /**
   * Sets the disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * This is called whenever form control is updated
   * Will be called when someone will call -> form.patchValue({control: 'value'}) or form.setValue({control: 'value'})
   */
  writeValue(value: T): void {
    this.value = value;
    this.getErrors();
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Marks the field as touched as soon as focus is lost
   */
  markInputAsTouched(): void {
    this.onTouched();
    this.getErrors();
    this.blurred.emit();
  }

  getErrors(): void {
    if (!this.control) {
      return;
    }
    let errorKey = '',
      errorVal = {};

    if (this.control.errors) {
      errorKey = Object.keys(this.control.errors)[0];
      errorVal = this.control.errors[errorKey];
    }

    errorVal =
      typeof errorVal === 'object' && errorVal !== null
        ? { ...errorVal, controlName: this.formControlName }
        : { controlName: this.formControlName, message: errorVal };

    if (errorKey) {
      if (this.translationPrefix) {
        this.errorMessage = translate(`${this.translationPrefix}.validation.${this.formControlName}.${errorKey}`, {
          ...errorVal,
          label: this.label,
        });
      } else {
        this.errorMessage = translate(`forms.validation.${errorKey}`, { ...errorVal, label: this.label });
      }
      return;
    }

    this.errorMessage = undefined;
  }

  get id(): string {
    return (this.name || this.formControlName) + this.idSuffix;
  }
}
