<div *transloco="let translate" (click)="onSelect()" class="eg-pill" [ngClass]="{ 'eg-pill--small': small }">
  @if (type === 'enum') {
  <span class="eg-pill__status eg-pill__status--{{ colors ? colors[value] : 'grey' }}">{{ translate(translationPrefix + value) }}</span>
  } @if (type === 'number') {
  <span class="eg-pill__status eg-pill__status--{{ value < 0 ? 'lightRed' : 'grey' }}">{{ value }}</span>
  } @if (type === 'object') {
  <span class="eg-pill__status eg-pill__status--{{ colors ? colors[value] : 'lightGreen' }}">{{
    translate(translationPrefix + value?.name)
  }}</span>
  }
</div>
