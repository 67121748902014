<div *transloco="let translate" class="auth-container">
  <div fxLayout="column" class="auth-content">
    <h1>
      {{ translate('login.resetPassword.title') }}
    </h1>

    <form class="login-form" fxLayoutAlign="space-around" fxLayout="column" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
      @if (formError) {
      <div class="alert alert-danger">
        {{ errorMessage }}
      </div>
      }
      <div fxFlex="100" fxLayout="column">
        <eg-input
          formControlName="code"
          name="code"
          fieldType="M"
          class="pb-16"
          autocomplete="off"
          [label]="translate('login.code.title')"
          [placeholder]="translate('login.code.title')"
        >
        </eg-input>

        <h3>
          {{ translate('login.resetPassword.validation.newPassword') }}
        </h3>
        <eg-input
          formControlName="password"
          name="password"
          type="password"
          fieldType="M"
          class="mb-16"
          autocomplete="off"
          [label]="translate('login.resetPassword.new')"
          [placeholder]="translate('login.resetPassword.new')"
        >
        </eg-input>

        <eg-input
          formControlName="confirmPassword"
          name="confirmPassword"
          type="password"
          fieldType="M"
          class="mb-16"
          autocomplete="off"
          [label]="translate('forms.confirmPassword.title')"
          [placeholder]="translate('forms.confirmPassword.title')"
        >
        </eg-input>

        <div class="btn-container" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
          <h6 class="btn-sidebar">
            @if (!counter) {
            <span>{{ translate('login.code.noCode') }}</span>
            <br />
            <span class="link--basic" (click)="resendCode()">
              {{ translate('login.code.resendCode') }}
            </span>
            } @else { }
          </h6>
          <button class="btn btn-submit" type="submit">
            {{ translate('login.btn.reset') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
