<h1>Components</h1>

<h2>Text</h2>
<section>
  <h1>Header h1</h1>
  <h2>Header h2</h2>
  <h3>Header h3</h3>
  <h4>Header h4</h4>
  <h5>Header h5</h5>
  <h6>Header h6</h6>
  <p>body text</p>
  <p><strong>strong body text</strong></p>
  <p><small>small body text</small></p>
</section>

<h2>Selections</h2>
<section>
  <h3>Radio select</h3>
  <eg-radio-select [items]="radioSelectItems" value="i1"></eg-radio-select>
  <h3>Tile select</h3>
  <eg-tile-select [options]="tileSelectItems"></eg-tile-select>
  <h3>Icon select</h3>
  <eg-icon-select [items]="iconSelectItems" [value]="true"></eg-icon-select>
</section>
<h2>Buttons</h2>
<section>
  <div>
    <h3>Large</h3>
    <button egButton egStyle="primary" egButtonSize="large">Primary</button>
    <button egButton egStyle="secondary" egButtonSize="large">Secondary</button>
    <button egButton egStyle="danger" egButtonSize="large">Danger</button>
    <button egButton egStyle="none" egButtonSize="large">None</button>
  </div>
  <div>
    <h3>Default</h3>
    <button egButton egStyle="primary" egButtonSize="default">Primary</button>
    <button egButton egStyle="secondary" egButtonSize="default">Secondary</button>
    <button egButton egStyle="danger" egButtonSize="default">Danger</button>
    <button egButton egStyle="none" egButtonSize="default">None</button>
  </div>
  <div>
    <h3>Small</h3>
    <button egButton egStyle="primary" egButtonSize="small">Primary</button>
    <button egButton egStyle="secondary" egButtonSize="small">Secondary</button>
    <button egButton egStyle="danger" egButtonSize="small">Danger</button>
    <button egButton egStyle="none" egButtonSize="small">None</button>
  </div>
  <div>
    <button egButton egType="prev">Prev</button>
    <button egButton egType="next">Next</button>
  </div>
</section>

<h2>Checks</h2>
<section>
  <h3>checkbox</h3>
  <eg-checkbox label="checkbox"></eg-checkbox>
  <eg-checkbox label="checkbox checked" value="true"></eg-checkbox>
  <h3>Toggle</h3>
  <eg-toggle></eg-toggle>
</section>

<h2>Input</h2>
<section>
  <eg-input label="Input"></eg-input>
  <eg-input label="Input disabled" disabled="true"></eg-input>
  <eg-number-selection label="Number selection"></eg-number-selection>
  <eg-number-selection label="Number selection disabled" disabled="true"></eg-number-selection>
  <eg-date label="Date"></eg-date>
  <eg-date-range label="Date range"></eg-date-range>
  <eg-time label="Time"></eg-time>
  <eg-time-range-picker label="Time range"></eg-time-range-picker>
</section>

<h2>Alerts</h2>
<section>
  <eg-alert type="info" message="text"></eg-alert>
  <eg-alert type="warning" message="text"></eg-alert>
  <eg-alert type="error" message="text"></eg-alert>
</section>

<h2>Buttons</h2>
<section>
  <eg-tile-add label="Tile select"></eg-tile-add>
  <eg-tile-add label="Tile select disabled" disabled="true"></eg-tile-add>
</section>

<h2>Uploads</h2>
<section>
  <eg-file-upload label="File upload"></eg-file-upload>
  <eg-upload label="Upload"></eg-upload>
</section>
