import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tab } from '../tab.interface';
import { filter } from 'rxjs/operators';

@Injectable()
export class TabGroupService {
  private tabsSubject: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);
  private selectedTabSubject: BehaviorSubject<Tab> = new BehaviorSubject<Tab>(undefined);

  setTabs(tabs: Tab[]): void {
    const selectedIndex = tabs.findIndex(({ selected }) => selected);
    this.tabsSubject.next(tabs);

    if (selectedIndex !== -1) {
      this.selectedTabSubject.next(tabs[selectedIndex]);
    }
  }

  selectTabById(id: string): void {
    const tabs = this.tabsSubject.value;

    const tab = tabs.find((tab) => (tab.tabId ? tab.tabId === id : tab.id === id));

    if (tab.disabled) {
      throw new Error(`Disabled tab ${tab.name} could not be selected.`);
    }

    this.selectedTabSubject.next(tab);
  }

  getNextTabId(): string {
    const tabs = this.tabsSubject.value;
    const tabIdx = tabs.indexOf(this.selectedTabSubject.value);
    if (tabIdx < tabs.length - 1) {
      if (tabs[tabIdx + 1].tabId) {
        return tabs[tabIdx + 1].tabId;
      } else {
        return tabs[tabIdx + 1].id;
      }
    }
    return null;
  }

  getPreviousTabId(): string {
    const tabs = this.tabsSubject.value;
    const tabIdx = tabs.indexOf(this.selectedTabSubject.value);
    if (tabIdx > 0) {
      if (tabs[tabIdx - 1].tabId) {
        return tabs[tabIdx - 1].tabId;
      } else {
        return tabs[tabIdx - 1].id;
      }
    }
    return null;
  }

  updateTabById(id: string, tabData: Partial<Tab>): void {
    const previousTabs = this.tabsSubject.value;
    const tabIndex = previousTabs.findIndex((tab) => (tab?.tabId ? tab.tabId === id : tab.id === id));
    previousTabs[tabIndex] = { ...previousTabs[tabIndex], ...tabData };

    this.tabsSubject.next([...previousTabs]);
  }

  get tabs$(): Observable<Tab[]> {
    return this.tabsSubject.asObservable();
  }

  get selectedTab$(): Observable<Tab> {
    return this.selectedTabSubject.asObservable().pipe(filter((value) => !!value));
  }
}
