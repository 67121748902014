import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco-root.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@railmybox/layout';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { AuthInterceptor, NoCacheInterceptor, RefreshTokenInterceptor, SessionInterceptor } from './interceptors';
import { Configuration, ConfigurationParameters } from '@railmybox/api-user';
import { AppRoutingModule } from './app-routing.module';
import { CookiebotConfig } from './cookiebot.config';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { ToastModule } from 'primeng/toast';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { MessageService } from 'primeng/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UiModule } from '@eg/ui';
import { ComponentsComponent } from './components/components.component';

registerLocaleData(localeDe);

export function appendGoogleMapsFactory() {
  return () => {
    return new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&language=en`;
      script.addEventListener('load', () => {
        resolve();
      });
      document.body.appendChild(script);
    });
  };
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    credentials: {
      main_auth: 'xxx',
    },
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [AppComponent, ComponentsComponent],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    ReactiveFormsModule,
    ToastModule,
    FlexModule,
    LayoutModule,
    UiModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    environment.enableCookieBanner ? NgxCookiebotModule.forRoot(CookiebotConfig) : [],
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appendGoogleMapsFactory,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true,
    },
    {
      provide: Configuration,
      useFactory: apiConfigFactory,
    },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
