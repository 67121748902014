import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { SelectItem } from '../select.interface';

@Injectable({
  providedIn: 'root',
})
export class TranslateSelectItemsService {
  constructor(private translocoService: TranslocoService) {}

  getTranslatedSelectItems(selectItems: SelectItem[]) {
    return selectItems.map((selectItem) => {
      return { label: this.translocoService.translate(selectItem.label), value: selectItem.value };
    });
  }
}
