<ul
  fxFlex="100"
  class="eg-select-illustrated"
  [class.eg-select-illustrated--disabled]="disabled"
  fxLayoutAlign="center start"
  [class.eg-select-illustrated--small]="isSmall"
  *transloco="let translate"
>
  @for (option of options; track option) {
  <li
    fxLayoutAlign="start end"
    fxFlex="248px"
    [ngStyle]="{ 'background-image': 'url(' + option.icon + ')' }"
    [class.selected]="isSelected(option)"
    [class.unselected]="isUnselected(option)"
    [class.disabled]="option.disabled"
    (click)="onOptionSelect(option)"
    [egTooltip]="option.disabled && option.id === 2 ? translate(option.tooltip + 'Disabled') : translate(option.tooltip)"
  >
    <p>{{ translate(option.label) }}</p>
    <div class="selected__mark">
      <img src="/assets/icons/cell_badge_check.svg" alt="BADGE" />
    </div>
  </li>
  }
</ul>
