<label fxFlex="100" class="eg-number-selection" [class.eg-number-selection__disabled]="control?.disabled || disabled">
  <span fxLayout="column" fxFlex="75" fxLayoutAlign="space-around start">{{ labelWithSuffix }}</span>
  <div fxLayout="column" fxFlex="25" fxLayoutAlign="space-around end">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img src="/assets/icons/field_minus.svg" alt="DECREMENT" (click)="decrementQuantity()" />
      <input
        type="number"
        class="pl-2"
        [disabled]="control?.disabled || disabled"
        [min]="min"
        [max]="max"
        [valueAsNumber]="value"
        (keyup)="onValueChange($event.target['value'])"
      />
      <img src="/assets/icons/field_plus.svg" alt="INCREMENT" (click)="incrementQuantity()" />
    </div>
  </div>
</label>
