<ng-container *transloco="let translate">
  <eg-card-select
    [class.loading-search__destination]="locationType === 'destination'"
    [class.loading-search__start]="locationType === 'start'"
    [items]="groupedAddresses$ | async"
    [searchPlaceholder]="translate('forms.locationSearch.input.placeholder')"
    [itemTemplate]="itemTemplate"
    [tileTemplate]="tileTemplate"
    [disabled]="control?.disabled || disabled"
    (searchValueChange)="onSearchValueChanged($event)"
    (valueSelection)="onAddressSelect($event)"
    [keyboardNavigation]="false"
  >
  </eg-card-select>

  <ng-template #tileTemplate>
    <div fxFlex="44px" fxLayoutAlign="center center">
      @if (selectedImage) {
      <img [src]="selectedImage" class="mr-8" alt="SVG" />
      }
    </div>
    <div fxFlex="100" class="card-select__tile__content pl-16" fxLayout="column" fxLayoutAlign="center">
      <strong class="mt-8">{{ selectedAddress?.type ? translate('waypoints.' + selectedAddress?.type) : labelWithSuffix }}</strong>
      <p class="my-4">
        {{ selectedAddress?.formattedAddress || translate('forms.locationSearch.addressDefault') }}
      </p>
    </div>
  </ng-template>

  <ng-template #itemTemplate let-item="item" let-selectCallback="selectCallback">
    <div fxFlex="100" fxLayoutAlign="start center" class="px-16 mb-4 location-search__dropdown__group-label card-select__item-group">
      <img src="{{ baseUrlPath }}/assets/icons/location_{{ item.type.toLowerCase() }}.svg" class="mr-8" alt="SVG" />
      <span>{{ translate('waypoints.' + item.type) }}</span>
    </div>
    @for (address of item.addresses; track address) {
    <div fxFlex="100" class="px-16 location-search__dropdown__group__item card-select__item-group" (click)="selectCallback(address)">
      {{ address.formattedAddress }}
    </div>
    }
  </ng-template>
</ng-container>
