import { ChangeDetectorRef, Component, Input, Optional, ViewChild } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer } from '@angular/forms';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'eg-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TextEditorComponent)],
})
export class TextEditorComponent extends AbstractValueAccessorComponent<string> {
  @Input() prefixInputPadding = 10;
  prefixContainerWidth?: number;

  @ViewChild('editor') editor: Editor;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  onTextChange(event: any) {
    this.value = event?.htmlValue;
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  onInitEditor() {
    if (this.editor) {
      const quill = this.editor.getQuill();
      quill.root.innerHTML = this.value;
    }
  }
}
