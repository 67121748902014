import { HttpResponse } from '@angular/common/http';

export function downloadFile(response: HttpResponse<Blob>) {
  let fileName = '';
  const contentDisposition = response.headers.get('Content-Disposition');
  const fileBlob = response.body;

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
    if (filenameMatch) {
      fileName = filenameMatch[1];
    }
  }

  if (fileBlob) {
    triggerDownload(fileBlob, fileName);
  }
}

export function triggerDownload(blob: Blob, fileName: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName || 'file.xlsx';
  downloadLink.click();
}
