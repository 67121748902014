import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractValueAccessorComponent } from './abstract-value-accessor.component';
import dayjs from 'dayjs/esm';
import localData from 'dayjs/esm/plugin/localeData';
import 'dayjs/esm/locale/de';
import 'dayjs/esm/locale/en-gb';
import { ControlContainer } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';

dayjs.extend(localData);

@UntilDestroy()
@Component({ template: '' })
export abstract class AbstractDateComponent<T> extends AbstractValueAccessorComponent<T> implements OnInit {
  defaultDate = dayjs().hour(0).minute(0).second(0).toDate();
  minDateFormatted: Date;
  defaultMinDate: Date;
  @Input() set minDate(value: dayjs.Dayjs) {
    if (value) {
      const minDateValue = dayjs(value);
      this.defaultDate = minDateValue.tz(dayjs.tz.guess()).hour(0).minute(0).second(0).toDate();
      this.defaultMinDate = minDateValue.toDate();
      this.minDateFormatted = minDateValue.toDate();
    }
  }
  maxDateFormatted: Date;
  defaultMaxDate: Date;
  @Input() set maxDate(value: dayjs.Dayjs) {
    if (value) {
      const maxDateValue = dayjs(value);
      this.defaultMaxDate = maxDateValue.toDate();
      this.maxDateFormatted = maxDateValue.toDate();
    }
  }
  @Input() set: string;
  @Input() cancel: string;
  localeFormat: string;
  monthRange: any;
  @Input() dateMin: Date;
  @Input() dateMax: Date;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer,
    private translocoService: TranslocoService,
    private configPrime: PrimeNGConfig
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe({
      next: (language: string) => {
        language === 'en' ? (language = 'en-gb') : language;
        this.monthRange = this.setLocaleMonthRange(language);
        this.primeNGMonths(language);
        this.localeFormat = this.configPrime.getTranslation('dateFormat');
        this.changeDetectorRef.detectChanges();
      },
    });
  }

  setLocaleMonthRange(locale: string) {
    let format: string;
    locale === 'de' ? (format = 'MM.YYYY') : (format = 'MM/YYYY');
    return format;
  }

  primeNGMonths(locale = 'en') {
    const dayjsLocale = dayjs().locale(locale).localeData();
    this.configPrime.setTranslation({
      dayNamesMin: dayjsLocale.weekdaysMin(),
      monthNames: dayjsLocale.months(),
      monthNamesShort: dayjsLocale.monthsShort(),
      firstDayOfWeek: dayjsLocale.firstDayOfWeek(),
      dateFormat: dayjsLocale.longDateFormat('L'),
      today: this.set,
      clear: this.cancel,
    });
  }
}
