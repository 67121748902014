<p-editor
  #editor
  [(ngModel)]="value"
  [class.no-label]="!labelWithSuffix"
  class="eg-input__editor"
  [disabled]="disabled"
  [readonly]="readonly"
  [placeholder]="placeholder"
  (onInit)="onInitEditor()"
  (onTextChange)="onTextChange($event)"
>
  <ng-template pTemplate="header">
    <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
    </span>
  </ng-template>
</p-editor>

<label [for]="id" [style.left.px]="prefixContainerWidth + prefixInputPadding" class="absolute eg-input__label">
  {{ labelWithSuffix }}
</label>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="eg-input__error"></eg-form-error>
}
