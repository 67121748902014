<div fxFlex="100" fxFlexFill fxLayout="row" fxLayoutAlign="start center" class="tile-add">
  <div fxFlex="50px" fxLayoutAlign="center center" class="tile-add__button" [ngClass]="{ 'mr-11': label }">
    <img src="/assets/icons/field_plus.svg" alt="ADD" />
  </div>

  @if (label) {
  <div fxFlex="100" fxLayoutAlign="start center" class="tile-add__label">
    <span>{{ label }}</span>
  </div>
  }
</div>
