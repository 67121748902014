@if (title && (isDocumentPresent || isContainerDocumentPresent || !disabled)) {
<h3 class="mb-4 mt-12">
  {{ title }}
</h3>
}

<input
  type="file"
  class="upload__input"
  [name]="formControlName"
  accept=".pdf"
  [readonly]="readonly"
  [disabled]="disabled"
  (change)="onChanged($event)"
  #upload
/>
@if (isDocumentPresent || isContainerDocumentPresent || !disabled) {
<div>
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px" class="upload__button" *transloco="let translate">
    @if (isDocumentScaning) {
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" *transloco="let translate">
      <eg-spinner size="20" fxLayout="column" fxLayoutAlign="center center"></eg-spinner>
      <p class="ml-15 my-0 scan-message">{{ translate('documents.scaningMessage') }}</p>
    </div>
    } @else {@if (!containerKey && !disabled && !(isDocumentPresent || isContainerDocumentPresent)) {
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <span class="upload__icon" (click)="upload.click()"></span>
      <span class="upload__label" (click)="upload.click()"> {{ label || labelWithSuffix }} </span>
    </div>
    } @if (containerKey && !disabled && !(isDocumentPresent || isContainerDocumentPresent)) {
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <span class="upload__icon" (click)="upload.click()"></span>
      <span class="upload__label" (click)="upload.click()"> {{ label || labelWithSuffix }} </span>
    </div>
    } @if (!containerKey) {
    <div fxFlex="100" fxLayout="column" fxLayoutGap="5px">
      @for (doc of document; track doc) {
      <div (click)="download(doc)" fxFlex="100" fxLayout="row" class="upload__file" fxLayoutAlign="start center">
        <div class="file" fxFlex="100" fxLayout="row" fxLayoutAlign="start space-between">
          <div fxFlex fxLayout="row" class="py-5">
            <span class="file-icon"></span>
            <span class="file-name"> {{ doc?.documentKey }} </span>
          </div>
          @if (!disabled) {
          <img
            src="/assets/icons/dropdown_delete_hover.svg"
            alt="TRASH"
            class="delete"
            (click)="removeSelection(doc?.documentKey, false)"
          />
          }
        </div>
      </div>
      }
    </div>
    } @if (containerKey && containerDocument[containerKey]) {
    <div fxFlex="100" fxLayout="column" fxLayoutGap="5px">
      @for (ctrDocument of containerDocument[containerKey]; track ctrDocument) {
      <div (click)="download(ctrDocument)" fxFlex="100" fxLayout="row" class="upload__file" fxLayoutAlign="start center">
        <div class="file" fxFlex="100" fxLayout="row" fxLayoutAlign="start space-between">
          <div fxFlex fxLayout="row" class="py-5">
            <span class="file-icon"></span>
            <span class="file-name"> {{ ctrDocument?.documentKey }} </span>
          </div>
          @if (!disabled) {
          <img src="assets/icons/trash.svg" class="delete" alt="delete" (click)="removeSelection(ctrDocument?.documentKey, true)" />
          }
        </div>
      </div>
      }
    </div>
    } @if (control?.touched && control?.errors && errorMessage) {
    <eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
    } }
  </div>
</div>
}
