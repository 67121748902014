export function toTitleCase(str: string): string {
  if (!str?.length) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function countByRegex(str: string, regex: RegExp): number {
  return str.split('').reduce((count, character) => (regex.test(character) ? count + 1 : count), 0);
}

export const toKebabCase = (str: string) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

export function generateRandomId(): string {
  return Math.floor(Math.random() * 1000000000).toString();
}
