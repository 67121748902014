<div egClickOutside (outsideClick)="closeActionMenu()" [class.menu__open]="displayMenuDropDown">
  <img class="pointer mt-5" src="/assets/icons/menu_dropdown.svg" alt="Drop" (click)="toggleMenuDisplay()" />

  @if (displayMenuDropDown) {
  <div fxLayout="row" class="items-select pointer">
    <ul class="menu-list">
      @for (menuItem of menuItems; track menuItem) {
      <li fxLayoutAlign="start center">
        <a fxLayoutAlign="start center" fxLayoutGap="8px" (click)="menuItem.action(actionEntity); closeActionMenu()">
          <img class="icon svg-to-primary" src="/assets/icons/{{ menuItem.icon }}.svg" alt="{{ menuItem.icon }}" />
          <!-- @TODO: Add icons for edit and delete here -->
          @if (menuItem.canTranslate) {
          <span>{{ menuItem.title | transloco }}</span>
          } @else {
          <span>{{ menuItem.title }}</span>
          }
        </a>
      </li>
      }
    </ul>
  </div>
  }
</div>
