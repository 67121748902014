<ng-container *transloco="let translate">
  <eg-card-select
    [items]="groupedCostsType$ | async"
    [itemTemplate]="itemTemplate"
    [tileTemplate]="tileTemplate"
    [disabled]="control?.disabled || disabled"
    (valueSelection)="onCostsTypeSelect($event)"
    [keyboardNavigation]="false"
    [clearable]="clearable"
    [value]="value"
    [hideSearch]="true"
  >
  </eg-card-select>

  <ng-template #tileTemplate>
    <div fxFlex="100" fxLayoutAlign="start center" class="label">
      @if (selectedCostType) {
      <span [class.label-top]="selectedCostType">{{ labelWithSuffix }}</span>
      }
      <span class="selected-value">
        {{ selectedCostType?.category || labelWithSuffix }}
        {{ selectedCostType?.name ? ' - ' + selectedCostType.name : '' }}</span
      >
    </div>
  </ng-template>

  <ng-template #itemTemplate let-item="item" let-selectCallback="selectCallback">
    <div fxFlex="100" fxLayoutAlign="start center" class="px-16 mb-4 category-search__dropdown__group-label card-select__item-group">
      <span>{{ item.category }}</span>
    </div>
    @for (costsType of item.types; track costsType) {
    <div fxFlex="100" class="px-16 category-search__dropdown__group__item card-select__item-group" (click)="selectCallback(costsType)">
      {{ costsType?.name }}
    </div>
    }
  </ng-template>
</ng-container>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
