import { ChangeDetectorRef, Component, ContentChild, ElementRef, Input, Optional, ViewEncapsulation } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer } from '@angular/forms';
import { FieldPrefixDirective, FieldSuffixDirective } from '@railmybox/shared/directive';

@Component({
  selector: 'eg-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TextareaComponent)],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TextareaComponent extends AbstractValueAccessorComponent<string | number> {
  @Input() prefixInputPadding = 10;
  @Input() rows = 1;

  @ContentChild(FieldPrefixDirective, { read: ElementRef }) set prefix(prefix: ElementRef) {
    if (prefix) {
      this.hasPrefixIcon = !!prefix.nativeElement;
      this.prefixContainerWidth = prefix.nativeElement.offsetWidth < 80 ? prefix.nativeElement.offsetWidth : 80;
    }
  }

  @ContentChild(FieldSuffixDirective, { read: ElementRef }) set suffix(suffix: ElementRef) {
    if (suffix) {
      this.hasSuffixIcon = !!suffix.nativeElement;
    }
  }

  hasPrefixIcon = false;
  hasSuffixIcon = false;
  prefixContainerWidth?: number;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  /**
   * Event handler when input is change.
   */
  textareaChanged(): void {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  onKeyup(event: any) {
    this.onChange(event.target.value);
    this.formControl?.setValue(event.target.value);
    this.changed.emit(event.target.value);
  }

  onNumberKeyup(event: any) {
    this.onChange(this.value);
    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }
}
