import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { VatValidationModel } from '@railmybox/api-user';
import dayjs from 'dayjs/esm';

/**
 * Checks at least one of the form fields have value.
 */
export const anyOf = (fields: string[]): ValidatorFn | any => {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const valid = fields.some((control) => !!formGroup.get(control).value);

    return valid ? null : { anyOfInvalid: true };
  };
};

/**
 * Checks if the value exists in the provided array
 * If found it sets error on the control
 */
export function isInArray(arr: (string | number)[] = []): ValidatorFn | any {
  return (control: FormControl): ValidationErrors | null => {
    const value = control.value as string | number;

    if (!value) {
      return null;
    }

    return arr.includes(value) ? { isInArray: true } : null;
  };
}

/**
 * (?=.*\d) checks a number
 * (?=.*[A-Z]) checks an uppercase letter
 * (?=.*[a-z]) checks a lowercase letter
 */
const PASSWORD_PATTERN = new RegExp('(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])');
const MIN_PASSWORD_LENGTH = 12;

export function passwordValidator(control: AbstractControl): ValidationErrors {
  const password: string = control.value || '';
  if (!password) {
    return { required: true };
  }

  if (!PASSWORD_PATTERN.test(password)) {
    return { pattern: true };
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return { minlength: { requiredLength: MIN_PASSWORD_LENGTH, actualLength: password.length } };
  }

  return null;
}

const EMAIL_VALIDATION_PATTERN =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+))/; // eslint-disable-line no-control-regex

export function emailValidator(control: AbstractControl): ValidationErrors {
  const email = control.value || '';
  if (EMAIL_VALIDATION_PATTERN.test(email)) {
    return null;
  }

  return { email: true };
}

export function requiredIf(forControl: string, relatedControl: string): ValidatorFn | any {
  return (group: FormGroup): ValidationErrors => {
    const forCtrl = group.get(forControl);
    const relatedCtrl = group.get(relatedControl);

    if (relatedCtrl.value && !forCtrl.value) {
      forCtrl.setErrors({ requiredIf: true });

      return null;
    }

    forCtrl.setErrors(null);
    return null;
  };
}

export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ match: true });

      return { invalid: true };
    }

    matchingControl.setErrors(null);
    return null;
  };
}

export function compareDates(firstControl: string, secondControl: string) {
  return (formGroup: FormGroup) => {
    const first = formGroup.controls[firstControl];
    const second = formGroup.controls[secondControl];

    if (!first.value || !second.value) {
      return null;
    }

    if (dayjs(first.value).diff(second.value) > 0) {
      second.setErrors({ smallerThan: true });

      return { invalid: true };
    } else if (dayjs(first.value).diff(second.value) === 0) {
      second.setErrors({ equal: true });

      return { invalid: true };
    } else {
      second.setErrors(null);
      return null;
    }
  };
}

export function allOf(parentField = 'address', fields = ['postalCode', 'streetName', 'city', 'country']): ValidatorFn | any {
  return (parentForm: FormGroup): ValidationErrors | null => {
    const mainControl = parentForm.get(parentField);

    if (!mainControl.value) {
      return null;
    }

    const error = fields.every((controlName) => !!parentForm.get(controlName).value) ? null : { allOf: true };

    if (error) {
      mainControl.setErrors(error);

      return { invalid: true };
    }

    mainControl.setErrors(null);
    return null;
  };
}

export const numbersRegEx = /^[0-9]*$/;

export function vatValidation(control: AbstractControl): ValidationErrors {
  const vat: VatValidationModel = control.value || '';
  if (vat.valid) {
    return null;
  }

  return { invalid: true };
}

export function containerCounter() {
  return (formGroup: FormGroup) => {
    const first = formGroup.controls['num20ftContainer'];
    const second = formGroup.controls['num40ftContainer'];
    const third = formGroup.controls['num45ftContainer'];

    if (!first.value || !second.value || !third.value) {
      return null;
    }

    if (first.value + second.value + third.value > 10) {
      second.setErrors({ sum: true });

      return { invalid: true };
    } else {
      second.setErrors(null);
      return null;
    }
  };
}

const companyIdRegEx = /^D\d{6}$/;

export function companyIdValidation(control: AbstractControl): ValidationErrors {
  const companyId = control.value || '';
  if (companyIdRegEx.test(companyId)) {
    return null;
  }
  if (companyId.valid) {
    return null;
  }

  return { companyId: true };
}

const multipleEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function multipleEmailValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value || '';
  const emails = value.trim().split(/\s+/);
  const emailSet = new Set<string>();

  for (const email of emails) {
    if (email.length > 0) {
      if (!multipleEmailRegex.test(email) || emailSet.has(email)) {
        return { email: true };
      }
      emailSet.add(email);
    }
  }

  return null;
}

export function timeValidation(control: AbstractControl): ValidationErrors {
  const time = control.value || '';
  if (time.length < 5) {
    return { invalid: true };
  }
  return null;
}
