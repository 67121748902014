import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginGuard, RailMyBoxRoles, routeGuard } from '@railmybox/auth';
import { ComponentsComponent } from './components/components.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'auth',
    loadChildren: () => import('@railmybox/auth').then((module) => module.AuthModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'booking',
    loadChildren: () => import('@railmybox/booking').then((module) => module.BookingModule),
    canActivate: [AuthGuard, routeGuard],
    data: { roles: [RailMyBoxRoles.CUSTOMER, RailMyBoxRoles.TYPIST] },
  },
  {
    path: 'profile',
    loadChildren: () => import('@railmybox/profile').then((module) => module.ProfileModule),
    canActivate: [AuthGuard, routeGuard],
    data: { roles: [RailMyBoxRoles.CUSTOMER] },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@railmybox/dashboard').then((module) => module.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    loadChildren: () => import('@railmybox/support').then((module) => module.SupportModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'wishlist',
    loadChildren: () => import('@railmybox/wishlist').then((module) => module.WishlistModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'components',
    component: ComponentsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...routes])],
  exports: [RouterModule],
})
export class AppRoutingModule {}
