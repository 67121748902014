<div class="card" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" *transloco="let translate">
  <svg-icon class="card__close" src="assets/icons/close.svg" (click)="onCloseClick()"></svg-icon>

  <img height="120px" width="120px" src="/assets/icons/summary/service_dangerous_goods.svg" class="svg-to-warning" />

  <div class="card__headline">
    {{ translate('confirmation.' + source + '.title') }}
  </div>
  <div class="card__text">
    {{ translate('confirmation.' + source + '.text') }}
  </div>

  <div fxFlex="100" fxLayout="row" fxLayoutGap="20px">
    <button egButton class="card__btn_secondary" (click)="onResponseClick('yes')">
      {{ translate('confirmation.action.yes') }}
    </button>
    <button egButton class="card__btn_primary" (click)="onResponseClick('cancel')">
      {{ translate('action.cancel') }}
    </button>
  </div>
</div>
