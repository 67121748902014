import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorModule } from '../form-error/form-error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { UploadComponent } from './upload.component';
import { SpinnerModule } from '../spinner';
import { ButtonModule } from '../button';

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, FormErrorModule, FormsModule, ReactiveFormsModule, FlexModule, TranslocoModule, SpinnerModule, ButtonModule],
  exports: [UploadComponent],
})
export class UploadModule {}
